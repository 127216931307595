import React from "react"

import "../css/pages/isolation.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const Isolation = () => (
  <Layout>
    <SEO title="Isolation à Laon dans l'Aisne (02)"
         description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux d'isolation dans l'Aisne et dans la Marne. Faites appel à nos artisans qualifiés pour isoler votre maison."}/>
    <div className={"page isolation"}>
        <div className={"header"}>
            <h1 className={"title"}>Travaux d'isolation à Laon</h1>
        </div>

        <Section>
            <h2>Pourquoi faire appel à nos artisans ?</h2>
            <p>
                Une maison mal isolée laisse s'échapper la chaleur en hiver et perd sa fraicheur en été. C'est pour cela qu'il est important d'isoler correctement votre maison. Une <strong>mauvaise isolation</strong> peut ainsi vous faire <strong>perdre de l’argent</strong> car vous devrez augmenter votre chauffage et votre climatisation à cause des déperditions thermiques. Effectuer des travaux d’isolation thermique permet de <strong>réduire votre consommations d’énergie liées au chauffage jusqu’à 80% !</strong>
            </p>

            <p>
                En plus de l’isolation thermique, il est important d’avoir une <strong>étanchéité à l’air efficace</strong> pour éviter les fuites d’air. Il est aussi nécessaire d’avoir une <strong>ventilation contrôlée (ou VMC)</strong> pour obtenir une bonne qualité de l’air intérieur.
            </p>

            <p>
                Enfin, il y d’autres raisons pour lesquelles il est important d’isoler votre maison. Comme par exemple <strong>l’isolation acoustique</strong>. Grâce à ces travaux qui amélioreront votre isolation thermique, vous pourrez <strong>bien vivre</strong> dans votre logement sans avoir de problème acoustique. C’est pour cela qu’il est important de choisir des<strong> matériaux efficaces et performants</strong>.
            </p>

            <p>
                Athies Batiment intervient pour isoler votre maison :
                <ul>
                    <li>En tant que <strong>conseiller</strong>, nous vous aidons à choisir les solutions d’isolation les plus efficaces et adaptées à votre projet.</li>
                    <li>En tant qu’acteur : nous réalisons la<strong> mise en œuvre</strong> de votre isolation, en garantissant un travail de qualité et performant.</li>
                </ul>
            </p>

            <p>
                Nos artisans, <strong>qualifiés et expérimentés</strong>, réaliseront vos travaux d'isolation selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
            </p>
        </Section>

        <Contact/>
    </div>
  </Layout>
)

export default Isolation
